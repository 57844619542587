import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import TopicCard from 'components/TopicCard'
import { IntlProvider } from 'contexts/intl/IntlContext'
import PageTitle from 'components/PageTitle'

export const query = graphql`
query AllTopics($templateKey: [String]!, $templatePath: [String]!) {
  allTopics: allMarkdownRemark(
    filter: { frontmatter: { path: { in: $templatePath }, templateKey: { in: $templateKey } } }
  ) {
    groups: group(field: { frontmatter: { topics: SELECT } }) {
      field: fieldValue
      count: totalCount
      groups: group(field: { frontmatter: { path: SELECT } }) {
        field: fieldValue
        count: totalCount
      }
    }
  }
}
`

const TopicList = ({ data, location, pageContext }) => {
  const allTopics = data.allTopics.groups
  var allTopicCount = []
  allTopics.forEach(topic => {
    const count = topic.count
    if (allTopicCount.includes(count)) {
    } else {
      allTopicCount.push(count)
    }
  })
  const type = pageContext.type
  const titleType = type + '.title'
  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            isBlogPost={false}
            title={formatMessage({ id: titleType.toString() })}
            description={formatMessage({ id: 'meta.description' })}
            keywords={formatMessage({ id: 'meta.keywords' })}
            url={formatMessage({ id: 'meta.url.blog' })}
          />
          <section className='section is-size-5-desktop is-size-6-touch'>
            <PageTitle pageTitle={<FormattedMessage id={titleType.toString()} />} />
            <div className='container'>
              <hr />
              <div className='field is-grouped is-grouped-multiline'>
                {Array.from(allTopics).map(topic => {
                  const arrayTopic = topic.field.split('-')
                  for (var i = 0; i < arrayTopic.length; i++) {
                    arrayTopic[i] = arrayTopic[i].charAt(0).toUpperCase() + arrayTopic[i].slice(1)
                  }
                  const customTopic = arrayTopic.join(' ')
                  return <TopicCard title={customTopic} topics={topic} />
                })}
              </div>
              <p className='mb-6'></p>
              <p className='has-text-centered'>
                <FormattedMessage id='checkOut' />{' '}
                <Link to={getPath('tools', locale)}>
                  <FormattedMessage id='tools.title' />
                </Link>
                {'.'}
              </p>
            </div>
          </section>
        </Layout>
      )}
    </IntlProvider>
  )
}

TopicList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default TopicList
