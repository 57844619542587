import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FcNews, FcReading, FcReadingEbook, FcServices, FcVideoCall } from 'react-icons/fc'

const TopicCard = ({ title, topics }) => {
  const newTopics = Array.from(topics.groups).map(path => {
    return path.field
  })

  const newTopicGroups = topics.groups
  return (
    <div className='card'>
      <header className='card-header has-background-dark'>
        <p className='card-header-title'>{title}</p>
      </header>
      <footer className='card-footer buttons has-addons'>
        {Array.from(newTopics).map(path => {
          const currentTopic = newTopicGroups.filter(value => {
            return value.field == path
          })[0]

          const count = currentTopic.count
          return (
            <Link className='button card-footer-item' to={topics.field}>
              {currentTopic.field == 'blog-post' && (
                <span className='icon'>
                  <FcReading />
                </span>
              )}
              {currentTopic.field == 'tutorial-post' && (
                <span className='icon'>
                  <FcNews />
                </span>
              )}
              {currentTopic.field == 'stories-post' && (
                <span className='icon'>
                  <FcReadingEbook />
                </span>
              )}
              {currentTopic.field == 'videos-post' && (
                <span className='icon'>
                  <FcVideoCall />
                </span>
              )}
              {currentTopic.field == 'services-post' && (
                <span className='icon'>
                  <FcServices />
                </span>
              )}
              <span>{count}</span>
            </Link>
          )
        })}
      </footer>
    </div>
  )
}

TopicCard.propTypes = {
  title: PropTypes.string.isRequired,
  topics: PropTypes.array.isRequired,
}

TopicCard.defaultProps = {
  title: '',
  topics: [],
}

export default TopicCard
